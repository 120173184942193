<template>
  <div class="w-full">
    <FastWay></FastWay>

    <div class="mt-10 mt-5 flex flex-col gap-5 flex-wrap shadow-xl p-5 bg-white rounded-xl" v-if="reference">
      <h2 class="mb-1 text-2xl self-center leading-none tracking-tight text-custom_organization-900">{{$t("Preview")}}</h2>
      <div class="w-full self-start">
        <span>{{$t("Reference")}} : {{ reference }}</span>
        <div class="w-full flex flex-col items-center p-5">
          <img class="max-h-56" v-if="selectedDatas[0] == 'hub'" src="./assets/Hub.jpg"/>
          <img class="max-h-56" v-if="selectedDatas[0] == 'cylinder'" src="./assets/Cylindre.jpg"/>
          <img class="max-h-56" v-if="selectedDatas[0] == 'crutch'" src="./assets/Béquille.jpg"/>
          <img class="max-h-56" v-if="selectedDatas[0] == 'reader'" src="./assets/LecteurClavier2.jpg"/>
        </div>
      </div>
      <div class="self-end">
        <button v-if="reference" @click="addToCart" class="rounded-full bg-custom_domain-700 text-light py-2 px-3 flex gap-2 hover:bg-custom_domain-800">
          {{$t("Add to cart")}}
          <i class="text-light gg-shopping-cart"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import OrderService from '@ivy/order-service'
import FastWay from './components/FastWay.vue'

export default {
  name: 'App',
  components: {
    FastWay
  },
  data() {
    return {
      reference: null,
      selectedDatas: null,
      origin: null,
    }
  },
  mounted() {
    window.addEventListener('request__item-preview--update', payload => {
      this.reference = payload.detail.reference
      this.selectedDatas = payload.detail.selectedDatas
      this.origin = payload.detail.origin
    })
  },
  methods: {
    addToCart() {
      OrderService.addToCart(
        this.reference,
        1,
        this.origin
      ).then(() => {
        window.dispatchEvent(new Event('request__order--update'))
        if(this.origin == 'configurator') {
          window.dispatchEvent(new Event('request__configurator--save-and-clear'))
        }
      })
    },
  }
}
</script>
