<template>
  <div id="fastway" class="relative z-0 mb-4">
    <div class="flex">
      <input type="text" v-model="query" @input="debounce(this.searchFastway, 1000);"
        class="block py-2 px-0 w-full text-sm text-zinc-950 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-custom_domain-500 focus:outline-none focus:ring-0 focus:border-custom_domain-600 peer" placeholder=""
        />
      <label class="flex gap-2 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-custom_domain-600 peer-focus:dark:text-custom_domain-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
        <i class="gg-bolt"></i>
        <span>{{$t("Manual reference")}}</span>
      </label>
      <button class="p-2 hover:bg-custom_domain-800 focus:ring-bg-custom_domain-800 bg-custom_domain-700 rounded-r-xl"
        :title="$t('Add to cart')" @click="addToCart">
        <i class="text-light gg-shopping-cart"></i>
      </button>
    </div>
    <ul
      class="bg-zinc-50 flex flex-col gap-0 rounded-b-xl shadow-xl"
      v-if="this.predictions && this.query">
      <li v-for="prediction in this.predictions" v-bind:key="prediction.reference"
        class="bg-zinc-50 cursor-pointer focus:bg-zinc-100 hover:bg-zinc-200 px-4 py-2 last:rounded-b-xl"
        :class="{'bg-zinc-100': prediction.selected}"
        @click="handleChose(prediction)">
        {{ prediction.reference }}
      </li>
    </ul>
  </div>
</template>

<script>
import ItemsService from '@ivy/items-service'
import OrderService from '@ivy/order-service'

export default {
  name: 'FastWay',
  data() {
    return {
      origin: 'FASTWAY',
      selectedItem: null,

      searchTimer: null,
      query: '',
      predictions: [],
      predictionId: 0,
    };
  },

  computed: {
    searchResults() {
      return this.predictions;
      // return this.predictions.filter((item) => {
      //   return item.name.toLowerCase().includes(this.searchTerm.toLowerCase());
      // });
    },
  },

  methods: {
    async searchFastway() {
      let res = await ItemsService.find(this.query, 3);
      this.predictions = res.data;
      console.log(this.predictions);
    },
    handleChose(prediction) {
      this.selectedItem = prediction;
      this.query = prediction.reference;
      this.predictions = [];
    },
    debounce(fn, wait){
      if(this.searchTimer) {
        clearTimeout(this.searchTimer); // clear any pre-existing timer
      }
      this.searchTimer = setTimeout(()=>{
        fn(); // call the function if time expires
      }, wait);
    },
    addToCart() {
      var _self = this;
      if(!this.selectedItem) {
        return;
      }
      OrderService.addToCart(this.selectedItem.reference, 1, this.origin)
        .then(() => {
          _self.query = "";
          window.dispatchEvent(new Event('request__order--update'))
        })
    }
  },
};
</script>

<style scoped>
.mh-48 {
  max-height: 10rem;
}
label {
  --ggs: 0.75
}
</style>